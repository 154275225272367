import React from 'react';

export const templateText = `
// Career Goals:
I aim to transition into a [specific role or industry] to leverage my expertise in [relevant field/skills]. 
In the next 5 years, I see myself [career goal], contributing to [company, industry, or broader impact].

// Passion for the Field:
My passion for [field or industry] comes from [specific experience, challenge, or motivation]. 
I’m driven to make an impact by focusing on [specific area or goal].

// Skills and Strengths:
My key strengths include [list relevant skills or experiences]. These have enabled me to [mention any achievements or outcomes], 
and I believe they will be instrumental in my success in this program.

// Achievements:
Some of my notable achievements include [mention professional or academic accomplishments], 
which have helped me grow and prepare for this opportunity.

// Learning Objectives:
I aim to deepen my knowledge of [specific areas or skills] through this program, 
which will support my goals of [specific career or personal objectives].
`;

export const sop_questions = {
  question1:
    'What motivated you to apply? Share your background, experience, and how this program aligns with your career goals.',
  question2:
    'In what ways do you believe this program can help you bridge any gaps in your knowledge or skill set?',
  question3:
    'What unique prespective or background do you bring to this program that sets you apart from other applicants?',
};

export const requiredSopLength = 250;

export const getTemplate = () => [
  {
    title: 'Career Goals',
    description:
      'I aim to transition into [specific role or industry], leveraging my expertise in [relevant field/skills]. Over the next 5 years, I see myself [career goal], contributing to [company, industry, or broader impact].',
  },
  {
    title: 'Passion for the Field',
    description:
      "My passion for [field or industry] comes from [specific experience, challenge, or motivation]. I'm driven to make an impact by focusing on [specific area or goal].",
  },
  {
    title: 'Skills and Strengths',
    description:
      'My key strengths include [list relevant skills or experiences]. These have enabled me to [mention any achievements or outcomes], and I believe they will be instrumental in my success in this program.',
  },
  {
    title: 'Achievements',
    description:
      'Some of my notable achievements include [mention professional or academic accomplishments], which have helped me grow and prepare for this opportunity.',
  },
  {
    title: 'Learning Objectives',
    description:
      'I aim to deepen my knowledge of [specific areas or skills] through this program, which will support my goals of [specific career or personal objectives].',
  },
];
