// @ts-nocheck
// Import required modules
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Stack,
  TextField,
  CircularProgress,
  Modal,
  styled,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { BASE_URL, getHeaders } from 'src/apis/urls';
import axios from 'axios';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import NewSopStrength from './NewSopStrength';
import WeakSopDialog from './WeakSopDialog';
import { requiredSopLength } from '.';

import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import StatementOfPurposeModal from './TemplateSOP';

const RevisitSOP = () => {
  const { formId, sopResponse, setShowSopRevisit, setShowSopQuestions } =
    useEfPipeline();
  const [isEditing, setIsEditing] = useState(false);
  const [statement, setStatement] = useState(sopResponse);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showWeakSOPPopup, setShowWeakSOPPopup] = useState(false);
  const [notInterested, setNotInterested] = useState(false);
  const [isSOPChanged, setIsSOPChanged] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
    setIsSOPChanged(false);
  };

  const handleTemplateButtonClick = () => {
    setShowTemplateModal(true);
  };

  const handleCloseTemplateModal = () => {
    setShowTemplateModal(false);
  };

  const trackButtonClick = async ({ source, eventName }) => {
    const body = {
      source,
      event_name: eventName,
      type: 'sop_revisit',
    };

    await axios
      .post(`${BASE_URL}/api/eligibility-forms/${formId}/activities`, body, {
        headers: getHeaders(),
      })
      .then((res) => {
        if (res?.data?.message.toLowerCase() === 'ok') {
          setLoading(false);
          setIsEditing(false);
          setShowSopRevisit(false);
          setShowSopQuestions(true);
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage('Error submitting SOP');
      });
  };

  const LightBlueTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      role='tooltip'
    />
  ))({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: '#E8FFFF',
      color: '#000',
      padding: '8px 12px',
      borderRadius: '12px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    },
  });

  const handleStatementChange = (event) => {
    setStatement(event.target.value);
    setIsSOPChanged(true);
  };

  const handleSubmitClick = () => {
    if (statement.length < requiredSopLength) {
      setShowWeakSOPPopup(true);
    } else {
      submitSOP();
    }
  };

  const submitSOP = async (isSubmitAnyway = false) => {
    setLoading(true);

    const body = {
      source: notInterested ? 'not_interested' : 'revisit_sop',
      event_name: isSubmitAnyway ? 'submit_anyway' : 'sop_revisit_completed',
      type: 'sop_revisit',
      sop_response: statement,
    };

    await axios
      .patch(`${BASE_URL}/api/eligibility-forms/${formId}/sop`, body, {
        headers: getHeaders(),
      })
      .then((res) => {
        if (res?.data?.status === 'success') {
          setLoading(false);
          setIsEditing(false);
          setShowSopRevisit(false);
          if (res?.data?.data) {
            setShowSopQuestions(res?.data?.data?.showSopQuestions);
          }
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage('Error submitting SOP');
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <WeakSopDialog
        open={showWeakSOPPopup}
        onClose={() => setShowWeakSOPPopup(false)}
        onSubmit={() => submitSOP(true)}
        loading={loading}
        setLoading={setLoading}
      />
      <Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
            padding: 2,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: { xs: 2, sm: 3, md: 4, lg: 6 },
              maxWidth: { xs: 720, lg: 900 },
              width: '100%',
              borderRadius: 2,
              margin: 'auto',
            }}
          >
            <Typography
              variant='h4'
              fontWeight='bold'
              gutterBottom
              color={'#257dd4'}
            >
              Revisit your Statement of Purpose
            </Typography>
            <Typography
              variant='body1'
              sx={{ mb: 2, fontWeight: '300', fontSize: '1.1rem' }}
            >
              We noticed that your Statement of Purpose seems to be lacking
              &amp; could be further strengthened. <br /> A well-crafted SOP
              usually contains certain elements that reflect your aspirations
              and objectives.
            </Typography>

            <Box
              sx={{
                padding: 3,
                backgroundColor: '#eaf6ff',
                borderRadius: 1,
                mb: 2,
              }}
            >
              {isEditing ? (
                <Box
                  sx={{
                    position: 'relative',
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  {/* TextField */}
                  <TextField
                    fullWidth
                    multiline
                    rows={10}
                    value={statement}
                    onChange={handleStatementChange}
                    variant='outlined'
                    disabled={loading}
                  />

                  <LightBlueTooltip
                    title='Show me a template'
                    placement='left'
                    aria-describedby='tooltip-description'
                  >
                    <Fab
                      size='small'
                      sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                        zIndex: 1,
                        borderRadius: '12px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
                        backgroundColor: '#fff',
                      }}
                      aria-label='Show Statement of Purpose Template'
                      onClick={handleTemplateButtonClick}
                    >
                      <TextSnippetIcon aria-hidden='true' />{' '}
                    </Fab>
                  </LightBlueTooltip>
                </Box>
              ) : (
                <Typography
                  component='pre'
                  sx={{ whiteSpace: 'pre-wrap' }}
                >
                  {statement}
                </Typography>
              )}
            </Box>

            <NewSopStrength sop={statement.trim()} />

            <Box
              display='flex'
              justifyContent='center'
              gap={2}
              flexWrap='wrap'
            >
              <Button
                variant='outlined'
                sx={{
                  textTransform: 'none',
                  color: 'red',
                  borderColor: 'red',
                  '&:hover': {
                    backgroundColor: 'red',
                    color: 'white',
                    borderColor: 'red',
                  },
                }}
                onClick={() => {
                  setShowWeakSOPPopup(true);
                  setNotInterested(true);
                }}
              >
                Not interested
              </Button>
              <Button
                variant='contained'
                color='primary'
                sx={{ textTransform: 'none' }}
                onClick={isEditing ? handleSubmitClick : handleEditClick}
                startIcon={!loading && !isEditing ? <EditIcon /> : null}
                disabled={isEditing && !isSOPChanged}
              >
                {loading ? (
                  <CircularProgress
                    size={20}
                    color='inherit'
                  />
                ) : isEditing ? (
                  'Re-submit my SOP'
                ) : (
                  'Edit your SOP'
                )}
              </Button>
            </Box>
          </Paper>
        </Box>
      </Stack>

      <StatementOfPurposeModal
        showTemplateModal={showTemplateModal}
        handleCloseTemplateModal={handleCloseTemplateModal}
      />
    </>
  );
};

export default RevisitSOP;
