/* eslint-disable no-unused-vars */
// @ts-nocheck
import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  Stack,
  useMediaQuery,
  useTheme,
  Grid,
  MobileStepper,
} from '@mui/material';
import FastrackOfferLetterNotYetReadyScreen from './FastrackOfferLetterNotYetReadyScreen';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import FastrackOfferLetterInfoPopup from './FastrackOfferLetterInfoPopup';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { BASE_URL, getHeaders } from 'src/apis/urls';
import { Concerns, getNextScreen, hiddenSteps } from '.';
const { TIME_MANAGEMENT_ISSUE, OTHER } = Concerns;

const Stepper = styled(MobileStepper)({
  alignSelf: 'center',
  position: 'absolute',
  top: 0,
  padding: 0,
  '& .MuiMobileStepper-root': {
    padding: 0,
  },
  '& .MuiMobileStepper-progress': {
    padding: 0,
    height: '6px',
    width: '100%',
  },
});

const TO_BE_AVOIDED_SCREENS_FOR_MAINTAINING_INTERNAL_STATE = [
  'ps_effectiveness_1',
  'clearing_interview_1',
  'clearing_interview_2',
  'clearing_interview_3',
];

const Screen4 = () => {
  const {
    shouldShowOfferLetterInfoPopup,
    setScreenSource,
    screenSource,
    offerLetter,
    setHasError,
    setErrorMessage,
    setScreenType,
  } = useFastrackPipeline();
  const [flowData, setFlowData] = useState({});
  const [workingHours, setWorkingHours] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTab = useMediaQuery('(max-width: 1200px)');

  useEffect(() => {
    fetchFlowData();
    return () => {};
  }, []);

  const fetchFlowData = async () => {
    try {
      const id = offerLetter.olId;
      const res = await axios.get(
        `${BASE_URL}/api/offer-letters/${id}/concerns?version=v4`,
        {
          headers: getHeaders(),
        }
      );

      const { concerns } = res.data;
      let data = {};
      let lastScreen = 'screen_1';

      concerns.forEach((concern) => {
        if (
          !TO_BE_AVOIDED_SCREENS_FOR_MAINTAINING_INTERNAL_STATE.includes(
            concern.screen
          )
        ) {
          data = {
            ...data,
            ...concern.response,
          };
        }

        lastScreen = concern.screen;
      });

      const terminationScreens = ['screen_8', 'clearing_interview_4'];
      if (terminationScreens.includes(lastScreen)) {
        if (data.is_ready_to_enroll) {
          // Since the user has agreed to enroll, no need to keep the user on v4 of fastrack offer letter.
          // In ideal scenario, this condition will never reach because if we had recorded the response of
          // 'screen_8', then we probably also would've updated the fastrack offer letter version so this
          // api call would never fire.
          setScreenType('v1');
          return;
        }
        lastScreen = 'screen_2b';
      }

      if (lastScreen === 'screen_2') {
        const { concern } = data;
        if (concern.toLowerCase() == TIME_MANAGEMENT_ISSUE.toLowerCase()) {
          setScreenSource('screen_3');
          return;
        } else if (concern.toLowerCase() == OTHER.toLowerCase()) {
          setScreenSource('screen_2b');
          return;
        }
      }

      if ('companies' in data) {
        data.companies.forEach((company, idx) => {
          if (!('roles' in company)) {
            data.companies[idx].roles = [];
          }
        });
      }

      setFlowData(data);

      if (concerns.length > 0) {
        const { dailyCommitments, sleepHours, workHours } = data;
        const hoursPerDay = 24;

        const totalOccupiedHoursPerDay =
          workHours + sleepHours + dailyCommitments;
        let freeHoursPerDay = hoursPerDay - totalOccupiedHoursPerDay;

        freeHoursPerDay = freeHoursPerDay < 0 ? 0 : freeHoursPerDay;
        lastScreen = getNextScreen({ lastScreen, freeHoursPerDay });
      }

      setScreenSource(lastScreen);
    } catch (error) {
      console.log(error);
      setScreenSource('screen_1');
    }
  };

  const saveFlowData = async (body) => {
    try {
      const id = offerLetter.olId;
      const res = await axios.post(
        `${BASE_URL}/api/offer-letters/${id}/concerns`,
        {
          ...body,
          version: 'v4',
        },
        {
          headers: getHeaders(),
        }
      );
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorMessage("Something went wrong. We couldn't save your response.");
    }
  };

  const getCurrentStep = () => {
    if (hiddenSteps.includes(screenSource)) {
      return 0;
    }

    if (screenSource.startsWith('screen_4')) {
      return 1;
    }

    if (screenSource.startsWith('screen_6')) {
      return 2;
    }

    if (screenSource.startsWith('screen_7')) {
      return 3;
    }
    return 4;
  };

  return (
    <>
      {/*Notes: - Commenting this out for now because of the increased drop off rate from the screen*/}
      {/* {shouldShowOfferLetterInfoPopup && <FastrackOfferLetterInfoPopup />} */}

      <Box
        alignSelf='center'
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#e3f2fd',
          paddingTop: '6rem',
          paddingBottom: '2rem',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          overflowY: 'auto',
          overflowX: 'hidden',
          '@media (max-width: 500px)': {
            paddingTop: '1.5rem',
            paddingLeft: '2rem',
          },
          '@media (min-height: 700px)': {
            paddingTop: '3rem',
          },
        }}
      >
        <Grid
          container
          spacing={4}
          sx={{
            position: 'relative',
            marginLeft: 0,
            backgroundColor: 'white',
            borderRadius: '12px',
            width: {
              xs: '80vw',
              sm: '80vw',
              md: '84vw',
              lg: '80vw',
              xl: '80vw',
            },
            height: 'fit-content',
            '@media (max-width: 1200px)': {
              height: 'fit-content',
              width: {
                xs: '95vw',
                sm: '85vw',
                md: '80vw',
              },
            },
            paddingTop: '2rem',
            paddingBottom: '3rem',
          }}
        >
          {getCurrentStep() > 0 && (
            <Stepper
              variant='progress'
              steps={5}
              position='static'
              activeStep={getCurrentStep()}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                padding: 0,
                '& .MuiMobileStepper-progress': {
                  width: '100%',
                },
              }}
            />
          )}
          <Grid
            item
            xs={12}
            container
            direction='column'
            height='100%'
            gap={2}
            style={{
              paddingLeft: 0,
            }}
          >
            <FastrackOfferLetterNotYetReadyScreen
              flowData={flowData}
              setFlowData={setFlowData}
              saveFlowData={saveFlowData}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Screen4;
