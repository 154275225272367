/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import { useEfPipeline } from '../../layouts/EfPipelineContext/EfPipelineContext';
import axios from 'axios';
import { BASE_URL, getHeaders } from '../../apis/urls';
import WelcomeScreen from './WelcomeScreen';
import Instructions from './Instructions';
import RunningTestLayout from './RunningTestLayout';
import FailureScreen from './FailureScreen';
import SuccessScreen from './SuccessScreen';
import ScreeningInterviewPreferanceScreen from './ScreeningInterviewPreferanceScreen';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import ScreeningInterviewPreferanceConfirmation from './ScreeningInterviewPreferanceConfirmation';
import ConfettiEffect from '../ConfettiEffect/ConfettiEffect';
import RescheduleTest from './FSTReschedule/RescheduleTest';
import HoldOnTight from './FSTReschedule/HoldOnTight';
import YourAllSet from './FSTReschedule/YourAllSet';
import WelcomeBack from './FSTReschedule/WelcomeBack';

const ScreeningTest = () => {
  const {
    loading,
    setLoading,
    formId,
    setFormId,
    setMessage,
    setShowScreeningTest,
    showFakeResponseAnalyzerForScreeningTest,
    setShowConfetti,
    showConfetti,
    screeningTestResult,
    setScreeningTestResult,
    screeningTestStatus,
    setScreeningTestStatus,
    exclusiveToken,
    setExclusiveToken,
    setScreeningTestVersion,
    setStartsIn,
    startsIn,
    setShowReschedule,
    setRescheduleTime,
    showWelcomeBack,
    rescheduleTime,
    setShowWelcomeBack,
    showRescheduleScreen,
    setShowRescheduleScreen,
    setShowAllSet,
  } = useEfPipeline();
  const [prevResponse, setPrevResponse] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [startedAt, setStartedAt] = useState(null);
  const [shouldConfirmScreeningInterview, setShouldConfirmScreeningInterview] =
    useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  useState(false);

  useEffect(() => {
    if (screeningTestResult === null && screeningTestStatus === null) {
      getSavedEfScreeningTest();
    }
    return () => {};
  }, []);

  useEffect(() => {
    // Function to check if reschedule_time has passed
    const checkRescheduleTime = () => {
      const currentTimeIST = moment().utcOffset(330); // Get current time in IST
      const rescheduleTimeIST = moment.utc(rescheduleTime).utcOffset(330); // Convert reschedule_time to IST

      if (currentTimeIST.isAfter(rescheduleTimeIST)) {
        setShowWelcomeBack(true);
      }
    };

    // Run check on component mount and set interval
    checkRescheduleTime();
    const interval = setInterval(checkRescheduleTime, 1000);

    // Clear interval on unmount
    return () => clearInterval(interval);
  }, [rescheduleTime]);

  const getSavedEfScreeningTest = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${BASE_URL}/api/eligibility-forms/${formId}/screening-test`,
        {
          headers: getHeaders(),
        }
      );

      const { message, response } = res.data;
      if (message) {
        setMessage(message);
        setFormId(null);
        setShowScreeningTest(false);
        return;
      }

      const {
        status,
        started_at,
        token = null,
        version = 'v1',
        starts_in,
        reschedule_time,
        reschedule_test,
      } = res.data;
      setScreeningTestStatus(status);
      setScreeningTestVersion(version);
      setStartsIn(starts_in);
      setRescheduleTime(reschedule_time);
      if (reschedule_time) {
        setShowAllSet(true);
      }
      setShowRescheduleScreen(reschedule_test);

      switch (status) {
        case 'created':
          setShowConfetti(true);
          break;
        case 'prefer_interview':
          break;
        case 'pending':
          setStartedAt(moment(started_at));
          if (typeof response === 'string') {
            setPrevResponse(JSON.parse(response));
          } else if (response) {
            setPrevResponse(response);
          }
          break;
        default:
          if (token) {
            setExclusiveToken(token);
            setScreeningTestResult('passed');
          } else {
            setScreeningTestResult('failed');
          }
          break;
      }
    } catch (err) {
      console.log(err);
      setHasError(true);
      setErrorMessage(
        err.response?.data?.message ?? 'Oop! Something went wrong.'
      );
    } finally {
      setLoading(false);
    }
  };

  const trackButtonClick = async ({ source, eventName }) => {
    const body = {
      source,
      event_name: eventName,
      type: 'screening_test',
    };

    try {
      await axios.post(
        `${BASE_URL}/api/eligibility-forms/${formId}/activities`,
        body,
        {
          headers: getHeaders(),
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const renderComponent = () => {
    const currentTime = moment();
    const startsInTime = startsIn ? moment.utc(startsIn).local() : null;
    if (screeningTestResult === 'passed') {
      return <SuccessScreen token={exclusiveToken} />;
    }

    if (screeningTestResult === 'failed') {
      return <FailureScreen />;
    }

    if (shouldConfirmScreeningInterview) {
      return (
        <ScreeningInterviewPreferanceConfirmation
          trackButtonClick={trackButtonClick}
          setShowInstructions={setShowInstructions}
          setScreeningTestStatus={setScreeningTestStatus}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
          setShouldConfirmScreeningInterview={
            setShouldConfirmScreeningInterview
          }
        />
      );
    }

    if (screeningTestStatus === 'prefer_interview') {
      return <ScreeningInterviewPreferanceScreen />;
    }

    if (showInstructions) {
      return (
        <Instructions
          setStartedAt={setStartedAt}
          setShowInstructions={setShowInstructions}
          setScreeningTestStatus={setScreeningTestStatus}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
        />
      );
    }
    if (
      screeningTestStatus === 'created' &&
      startsIn &&
      !currentTime.isAfter(startsInTime)
    ) {
      setShowConfetti(false);
      return (
        <HoldOnTight
          trackButtonClick={trackButtonClick}
          setShowInstructions={setShowInstructions}
          setShouldConfirmScreeningInterview={
            setShouldConfirmScreeningInterview
          }
        />
      );
    }
    if (
      screeningTestStatus == 'created' &&
      rescheduleTime == null &&
      showRescheduleScreen
    ) {
      setShowConfetti(false);
      return (
        <RescheduleTest
          formId={formId}
          trackButtonClick={trackButtonClick}
          setShowInstructions={setShowInstructions}
        />
      );
    }
    if (screeningTestStatus == 'created' && showWelcomeBack) {
      return (
        <WelcomeBack
          trackButtonClick={trackButtonClick}
          setShowInstructions={setShowInstructions}
        />
      );
    }
    if (screeningTestStatus == 'created' && rescheduleTime) {
      setShowConfetti(false);
      return (
        <YourAllSet
          rescheduleTime={rescheduleTime}
          trackButtonClick={trackButtonClick}
          setShowInstructions={setShowInstructions}
        />
      );
    }

    if (
      screeningTestStatus === 'created' ||
      (!startedAt && currentTime.isAfter(rescheduleTime))
    ) {
      return (
        <WelcomeScreen
          setShowRescheduleScreen={setShowRescheduleScreen}
          trackButtonClick={trackButtonClick}
          setShowInstructions={setShowInstructions}
          setShouldConfirmScreeningInterview={
            setShouldConfirmScreeningInterview
          }
          setShowReschedule={setShowReschedule}
          formId={formId}
        />
      );
    }

    return (
      <RunningTestLayout
        prevResponse={prevResponse}
        startedAt={startedAt}
        setExclusiveToken={setExclusiveToken}
        setScreeningTestResult={setScreeningTestResult}
        setHasError={setHasError}
        setErrorMessage={setErrorMessage}
        trackButtonClick={trackButtonClick}
      />
    );
  };

  const getBackgroundColor = () => {
    return 'inherit';
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {showConfetti && <ConfettiEffect />}
      <Box
        alignSelf='center'
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: getBackgroundColor(),
          alignItems: 'flex-start',
          paddingTop: '6rem',
          paddingBottom: '2rem',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          rowGap: '2rem',
          overflowY: 'auto',
          overflowX: 'hidden',
          '@media (max-width: 500px)': {
            paddingTop: '1.5rem',
          },
          '@media (min-height: 700px)': {
            paddingTop: '3rem',
          },
        }}
      >
        {!loading &&
          !showFakeResponseAnalyzerForScreeningTest &&
          renderComponent()}
      </Box>
    </>
  );
};

export default ScreeningTest;
