// @ts-nocheck
import React, { useState } from 'react';
import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Box,
  Stepper,
  StepLabel,
  Step,
  Grid,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import StepConnector from '@mui/material/StepConnector';
import axios from 'axios';
import { BASE_URL, getHeaders } from 'src/apis/urls';
import ScreeningTestWelcomeScreenExitPopup from 'src/components/ExitIntentPopups/ScreeningTestWelcomScreenExitPopup';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import { rescheduleTime } from '../ScreeningQuestions';
import acute from '../../../assets/acute_1.png';

const steps = ['Application form', 'Screening test', 'Offer letter'];

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    borderColor: '#1976D2',
  },
}));

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.grey[700],
  zIndex: 1,
  color: '#fff',
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

function CustomStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    3: <LockIcon fontSize='inherit' />,
  };

  return (
    <CustomStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </CustomStepIconRoot>
  );
}

const RescheduleTest = ({ formId, trackButtonClick, setShowInstructions }) => {
  const { setShowAllSet, setShowRescheduleScreen, setRescheduleTime } =
    useEfPipeline();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedTime, setSelectedTime] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRescheduleClick = async (minutes) => {
    const currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + minutes);
    const formattedTime = currentTime.toISOString();

    const body = {
      rescheduled_time: formattedTime,
    };

    try {
      setLoading(true);
      await axios.patch(
        `${BASE_URL}/api/eligibility-forms/${formId}/reschedule_test`,
        body,
        {
          headers: getHeaders(),
        }
      );
      setShowAllSet(true);
      setShowRescheduleScreen(0);
      setRescheduleTime(formattedTime);
    } catch (error) {
      console.error('Failed to reschedule time:', error);
    }
  };

  const startScreeningTest = ({
    source = 'reschedule_test',
    eventName = 'start_the_test_now',
  }) => {
    trackButtonClick({
      source,
      eventName,
    });
    setShowInstructions(true);
  };

  return (
    <>
      <ScreeningTestWelcomeScreenExitPopup
        trackButtonClick={trackButtonClick}
        source={'reschedule_test'}
      />
      <Stack
        spacing={3}
        alignItems='center'
        sx={{
          height: 'fit-content',
          width: {
            xs: '100%',
            sm: '100%',
            md: '85%',
            lg: '80%',
          },
          padding: {
            xs: '20px',
            sm: '30px',
            md: '2rem',
            lg: '3rem',
          },
          backgroundColor: 'white',
          borderRadius: '12px',
        }}
      >
        <Stack
          spacing={2}
          paddingTop='1rem'
          alignItems='center'
        >
          <Box
            sx={{ width: '100%', paddingBottom: '1rem', marginBottom: '30px' }}
          >
            <Stepper
              activeStep={1}
              alternativeLabel={isMobile}
              connector={<CustomConnector />}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    sx={{
                      fontFamily: 'Inter',
                      '& .MuiStepLabel-label.Mui-completed': {
                        color: index < 2 ? '#1976D2' : 'inherit',
                      },
                      '& .MuiStepLabel-label.Mui-active': {
                        color: index < 2 ? '#1976D2' : 'inherit',
                      },
                    }}
                    StepIconComponent={index === 2 ? CustomStepIcon : undefined}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
          >
            <Typography
              variant='h1'
              sx={{
                textAlign: 'center',
                fontFamily: 'Inter',
                color: '#1976d2',
                fontWeight: 'bold',
                fontSize: {
                  xs: '1.5rem',
                  sm: '1.7rem',
                  md: '1.8rem',
                  lg: '2rem',
                },
              }}
            >
              Let's find a better time for you to take the test.
            </Typography>
          </Stack>

          <Typography
            variant='body1'
            sx={{
              textAlign: 'center',
              fontFamily: 'Inter',
              fontWeight: 400,
              fontSize: {
                xs: '0.8rem',
                sm: '1rem',
                md: '1.2rem',
                lg: '1.4rem',
              },
            }}
          >
            Select your preferred time to reschedule the test
          </Typography>
        </Stack>

        <Grid
          container
          spacing={1}
          justifyContent='center'
          style={{ marginTop: 0 }}
          sx={{
            width: {
              xs: '90%',
              sm: '70%',
              md: '50%',
              lg: '30rem',
            },
            padding: {
              xs: '1rem',
              sm: '1.5rem',
              md: '2rem',
              lg: '2.5rem',
            },
          }}
        >
          {rescheduleTime?.map((timeOption, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              key={index}
            >
              <Box
                sx={{
                  padding: '10px',
                  border: '2px solid #1976D2',
                  textAlign: 'center',
                  cursor: 'pointer',
                  backgroundColor:
                    selectedTime === timeOption.minutes
                      ? '#1976D2'
                      : 'transparent',
                  color:
                    selectedTime === timeOption.minutes ? '#fff' : '#3687D7',
                  '&:hover': {
                    backgroundColor:
                      selectedTime === timeOption.minutes
                        ? '#1976D2'
                        : '#e3f2fd',
                  },
                  width: '100%',
                }}
                onClick={() => setSelectedTime(timeOption.minutes)}
                disabled={
                  selectedTime !== null && selectedTime !== timeOption.minutes
                }
              >
                <Typography
                  variant='body1'
                  sx={{ fontFamily: 'Inter', fontWeight: 'bold' }}
                >
                  {timeOption.label}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Stack
          style={{ marginTop: 0 }}
          spacing={2}
          sx={{
            width: '100%',
            maxWidth: '400px',
            textAlign: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant='contained'
            onClick={() => handleRescheduleClick(selectedTime)}
            disabled={!selectedTime || loading}
            sx={{
              backgroundColor: '#1976D2',
              color: '#fff',
              '&:hover': { backgroundColor: '#155A8A' },
              width: '50%',
              p: 2,
            }}
          >
            Confirm Your Time
          </Button>

          <Button
            variant='outlined'
            sx={{
              borderColor: '#1976D2',
              color: '#1976D2',
              '&:hover': { backgroundColor: '#e3f2fd' },
              width: '50%',
              p: 2,
            }}
            disabled={loading}
            onClick={startScreeningTest}
            startIcon={
              <Box
                component='img'
                src={acute}
                alt='Priority'
                sx={{ width: 24, height: 24 }}
              />
            }
          >
            Start the Test Now
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default RescheduleTest;
