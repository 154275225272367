// @ts-nocheck
// Import required modules
import React from 'react';
import { Modal, Paper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getTemplate } from '.';

const StatementOfPurposeModal = ({
  showTemplateModal,
  handleCloseTemplateModal,
}) => {
  const templateData = getTemplate();

  return (
    <Modal
      open={showTemplateModal}
      onClose={handleCloseTemplateModal}
      aria-labelledby='sop-template-modal-title'
      aria-describedby='sop-template-modal-description'
      aria-modal='true'
      disableEnforceFocus={false}
    >
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxHeight: '98%',
          overflow: 'auto',
          maxWidth: 600,
          padding: 4,
          borderRadius: 2,
          outline: 'none',
          userSelect: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
        role='document'
      >
        <Typography
          id='sop-template-modal-title'
          variant='h6'
          gutterBottom
          fontWeight='bold'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '10',
          }}
        >
          <span>📝 Statement of Purpose Template</span>
          <CloseIcon
            role='button'
            tabIndex={0}
            onClick={handleCloseTemplateModal}
            aria-label='Close Statement of Purpose Template Modal'
            onKeyDown={(e) => e.key === 'Enter' && handleCloseTemplateModal()}
          />
        </Typography>
        <Typography
          id='sop-template-modal-description'
          component='pre'
          sx={{ whiteSpace: 'pre-wrap', mb: 2 }}
        >
          <div
            id='sop-template'
            style={{
              padding: '20px',
              border: '1px solid #ddd',
              borderRadius: '8px',
              backgroundColor: '#E8FFFF',
            }}
          >
            {templateData.map((item, index) => (
              <div key={index}>
                <h4>{item.title}:</h4>
                <p>"{item.description}"</p>
              </div>
            ))}
          </div>
        </Typography>
      </Paper>
    </Modal>
  );
};

export default StatementOfPurposeModal;
